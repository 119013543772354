import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { db } from '../../../../../firebase.config';

// This is a custom hook that encapsulates the logic for fetching unit details
export const useGetUnitDetailsUsingParams = () => {
  const [unitDetails, setUnitDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { unitID } = useParams();

  useEffect(() => {
    const getUnitDetails = async () => {
      setLoading(true);
      const unitRef = doc(db, 'units', unitID);
      try {
        const docSnap = await getDoc(unitRef);
        if (docSnap.exists()) {
          setUnitDetails(docSnap.data());
        } else {
          console.log('No such document!');
          setError('No such document!');
        }
      } catch (err) {
        console.error('Error getting document:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getUnitDetails();
  }, [unitID]);

  return { unitDetails, loading, error };
};
