// Hooks and React Imports
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { ImArrowLeft2, ImMagicWand } from 'react-icons/im';
import { IoCalendarSharp } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';

import Wrapper from '../../../assets/wrappers/WorkshopTools';
import { db } from '../../../firebase.config';
import '../dashboard-pages/creationPages/Creations.css';
import Day from './Individual Day Preview/UnitDay';

const UnitPreview = () => {
  const [unitDetails, setUnitDetails] = useState({});
  const [dayCards, setDayCards] = useState([]);
  const { unitID } = useParams();

  async function getUnitDetails() {
    const unitRef = doc(db, 'units', unitID);
    const docSnap = await getDoc(unitRef);

    if (docSnap.exists()) {
      setUnitDetails(docSnap.data());
    } else {
      console.log('No such document!');
    }
  }

  function renderDays() {
    const days = [];
    for (let i = 1; i <= unitDetails.unitLength; i++) {
      days.push(
        <Day
          unitName={unitDetails.unitName}
          unitDetails={unitDetails}
          dayNumber={i}
          key={`Day ${i}`}
          day={`Day ${i}`}
          title={unitDetails[`day${i}`] ? unitDetails[`day${i}`].lessonName : ''}
          overview={unitDetails[`day${i}`] ? unitDetails[`day${i}`].overview : ''}
        />
      );
    }
    return days;
  }

  useEffect(() => {
    getUnitDetails();
  }, []);

  useEffect(() => {
    setDayCards(renderDays());
  }, [unitDetails]);

  return (
    <div className="creations">
      <Link to={'../unit-planner'}>{<ImArrowLeft2 className="historyicon" />}Back to Unit Planner</Link>
      <div className="pageheader" style={{ marginTop: '1rem' }}>
        <div>
          {<ImMagicWand className="historyicon" style={{ fontSize: '1.2rem', fontFamily: 'DMSansRegular' }} />}{' '}
          {unitDetails.unitName}
        </div>
      </div>
      <div
        style={{
          marginBottom: '1.3rem',
          fontFamily: 'DMSansRegular',
          fontWeight: '500',
          fontSize: '1.2rem',
          transform: 'translateY(-.5rem)',
          color: '#000000',
        }}
      >
        {<IoCalendarSharp className="historyicon" />} {unitDetails.unitLength} days
      </div>
      <Wrapper>
        <div className="jobs">{dayCards}</div>
      </Wrapper>
    </div>
  );
};

export default UnitPreview;
