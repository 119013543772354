import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import AuthService from '../../../services/Auth.service.js';
import './DashboardHome.css';

const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding-top: 10%;
  position: relative;

  @media (max-width: 749px) {
    height: auto;
  }
`;

const DashboardHome = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const checkSubscription = async () => {
      const subscribed = await AuthService.doesUserHaveActiveSubscription();
      setIsSubscribed(subscribed);
    };

    checkSubscription();
  }, []);

  return (
    <div>
      {!isSubscribed && (
        <div className="banner">
          🎉 Use code <strong>BACKTOSCHOOL</strong> at checkout to get 30% off!
        </div>
      )}
      <Header className="header-content">
        <h1>
          Welcome to Copilot 🚀
          <br />
          <br />
          <div className="cta">
            <h3>
              <a target="_blank" href="https://www.facebook.com/groups/1429875590870786" rel="noreferrer">
                👉 Educators: Click here to join our Facebook group!
              </a>
            </h3>
          </div>
        </h1>
      </Header>
    </div>
  );
};

export default DashboardHome;
