import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from 'firebase/auth';
import { addDoc, collection, onSnapshot } from 'firebase/firestore';
import { toast } from 'react-toastify';

import { db } from '../firebase.config';

const StripePubKey = 'pk_live_zp7u9YDsT6HrrwHs3UVQVmXN00SZrKzQp5';

const notify = (text) =>
  toast(text, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'light',
    closeButton: false,
  });

export const payPlan = async (planId, setIsLoading) => {
  notify('🚀 Loading Secure Checkout!');

  const auth = getAuth();
  console.log('PLAN ID', planId);

  await (async () => {
    const url = window.location.origin;
    const colRef = collection(db, 'users', auth.currentUser.uid, 'checkout_sessions');
    const docRef = await addDoc(colRef, {
      price: planId,
      success_url: `${url}/successful-payment`,
      cancel_url: `${url}/pricing`,
      allow_promotion_codes: true,
    });

    onSnapshot(docRef, async (snap) => {
      const { error, sessionId } = snap.data();

      if (error) {
        notify(error.message);
      }

      if (sessionId) {
        const stripe = await loadStripe(StripePubKey);
        await stripe.redirectToCheckout({ sessionId });
        setIsLoading(false);
      }
    });
  })();
};
