import { getAuth } from 'firebase/auth';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const auth = getAuth();
  const location = useLocation();

  // Check if the URL contains the discount code parameter
  const searchParams = new URLSearchParams(location.search);
  const discountCode = searchParams.get('discount');

  // If there's no user or the access token is null, redirect to the sign-in page
  if (!auth?.currentUser || auth?.currentUser?.accessToken === null) {
    // Construct the sign-in URL with the discount parameter if it exists
    const signInUrl = discountCode ? `/sign-in?redirect=/pricing?discount=${discountCode}` : '/sign-in';
    return <Navigate to={signInUrl} />;
  }

  return children;
};

export default ProtectedRoute;
