/* eslint-disable */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetUnitDetailsUsingParams } from './getUnitDetailsUsingParams';
import './FilesListView.css';

const FilesListView = (props) => {
  const navigate = useNavigate();
  const { unitID } = useParams();
  const { dayNumber } = props;

  const { unitDetails, loading, error } = useGetUnitDetailsUsingParams();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Extract the unitOverview object
  const { unitOverview } = unitDetails || {};

  // Access the correct day object using the dayNumber prop
  const dayKey = `day${dayNumber}`; // Construct the key for the day
  const currentDay = unitOverview ? unitOverview[dayKey] : null;

  // Helper function to generate a unique key for each resource
  const generateKey = (prefix, index) => `${prefix}-${index}`;

  // Combined click handler for completions, quizzes, and powerpoints
  const handleResourceClick = (resource, type) => {
    let baseUrl = '';
    switch (type) {
      case 'completion':
        baseUrl = `/tool/generator/context-builder/${unitID}/${dayNumber}?id=${resource.completionRefId}&type=1`;
        break;
      case 'quiz':
        baseUrl = `/tool/custom/quiz-builder/${unitID}/${dayNumber}/${resource.quizRefId}`;
        break;
      case 'powerpoints':
        baseUrl = resource.urlToStore; // Assuming this is the URL to the download link
        break;
      default:
        baseUrl = '#';
        break;
    }

    // Open the link in a new tab
    window.open(baseUrl, '_blank');
  };

  // Combine quizzes, completions, and powerpoints into a single array of resources
  const resources = [
    ...(currentDay?.completions || []).map((completion) => ({
      ...completion,
      type: completion.completionRefId ? 'completion' : 'oldCompletion',
    })),
    ...(currentDay?.quizzes || []).map((quiz) => ({ ...quiz, type: 'quiz' })),
    ...(currentDay?.powerpoints || []).map((powerpoint) => ({ ...powerpoint, type: 'powerpoints' })),
  ];

  return (
    <div className="FilesListViewContainer">
      <div className="headerRow">
        <div className="headerColumn">Resource Name</div>
        <div className="headerColumn">Reference ID</div>
      </div>
      {resources.length > 0 ? (
        resources.map((resource, index) => (
          <div
            key={generateKey(resource.type, index)}
            className="fileRow"
            onClick={() => handleResourceClick(resource, resource.type)}
          >
            <div className="fileColumn">
              {resource.type === 'quiz'
                ? resource.quizName
                : resource.type === 'completion'
                ? resource.toolName
                : resource.type === 'powerpoints'
                ? resource.powerpointName
                : 'Resource Name Goes Here'}
            </div>
            <div className="fileColumn">
              {resource.quizRefId || resource.completionRefId || resource.powerpointRefId || resource.id}
            </div>
          </div>
        ))
      ) : (
        <div className="fileRow">No resources found</div>
      )}
    </div>
  );
};

export default FilesListView;
